import { IonButton, IonIcon, IonModal, IonText, useIonViewDidEnter } from "@ionic/react"
import { useGetCertificate } from "hooks/sessions/useGetCertificate"
import { useRef } from "react";
import certificado from 'assets/certificado.jpg'
import { Filesystem, Directory } from '@capacitor/filesystem';
import { downloadOutline } from "ionicons/icons";
import { useSetRecoilState } from "recoil";
import { toastState } from "recoil/ToastAtom";
import CloseSvg from 'assets/md-close.svg';
import CheckIcon from 'assets/check_icon.svg';

interface IModal {
    isOpen: boolean;
    handleClose: () => void;
    coachName:string,
    sessionName:string,
    dateSession:string,
    userName:string,
    base64:string
  }

export const CertificadoModal : React.FC <IModal>= ({ isOpen, handleClose, coachName, dateSession, sessionName, userName, base64})=>{ 
    const modal = useRef<HTMLIonModalElement>(null);
    const setToast = useSetRecoilState(toastState);
    function dismiss() {
        handleClose();
        modal.current?.dismiss();
    }

    const writeSecretFile = async () => {
        await Filesystem.writeFile({
          path: `certificado-${sessionName}.pdf`,
          data: base64,
          directory: Directory.Documents,
        });
        

        const downloadLink = document.createElement("a");
        downloadLink.href = 'data:application/octet-stream;base64,' + base64;
        downloadLink.download = "convertedPDFFile.pdf";
        downloadLink.click();

        setToast({
            isOpen: true,
            color: 'success',
            message: 'El certificado se descargo satisfactoriamente en la carpeta Documentos',
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
      };

    return(
        <IonModal isOpen={isOpen} ref={modal} className="ul-modal" onDidDismiss={dismiss}>
            <img src={certificado} />
            <div style={{ width:'100%', position:'absolute',top:'41%', left:0, textAlign:'center'}}>
                <IonText style={{ fontSize:26 }}> {userName} </IonText>
            </div>
            <div style={{ width:'80%', position:'absolute',top:'54%', left:'10%', textAlign:'center'}}>
                <IonText style={{ fontSize:13 }}> por asistir a la charla sobre {sessionName} a cargo de <span style={{ fontWeight:700 }}>{coachName}</span> el día {dateSession} </IonText>
            </div>
            <div style={{ position:'absolute', width:'100%', top:'5%', left:'80%'}}>
                <IonButton color={'orange'} fill="clear" onClick={writeSecretFile}>
                    <IonIcon slot="icon-only" icon={downloadOutline}></IonIcon>
                </IonButton>
            </div>
        </IonModal>
    )
}