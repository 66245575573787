import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, useIonRouter, IonButtons, IonBackButton, IonIcon, IonFooter, useIonViewWillEnter, IonToast, useIonViewDidEnter, isPlatform, IonMenuButton } from '@ionic/react';
import { arrowBackOutline, mail } from 'ionicons/icons';
import './loginSignIn.css';
import { GoogleButton } from './components/GoogleButton';
import { FacebookButton } from './components/FacebookButton';
import { Divider } from 'components/Divider';
import { useState } from 'react';
import { SignUpForm } from 'components/forms/SignUpForm';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { avatarState } from 'recoil/AvatarAtom';
import { toastState } from 'recoil/ToastAtom';
import { IAppleJWT, IAuthWithApple, IAuthWithCredentials } from 'services/interfaces/AuthLogin';
import { useAuthWithGoogle } from 'hooks/auth/useAuthWithGoogle';
import { useEffect } from 'react';
import { Device } from '@awesome-cordova-plugins/device';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AppleButton } from './components/AppleButton';
import { ASAuthorizationAppleIDRequest, SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple';
import { UseAuthWithApple } from 'hooks/auth/UseAuthWithApple';
import decode from 'jwt-decode'

export const LoginSignUp: React.FC = () => {

  const navigation = useIonRouter()
  const [showCreateAcc, setShowCreateAcc] = useState(false)
  const [toast, setToast] = useRecoilState(toastState)
  const setAvatarState = useSetRecoilState(avatarState)
  const tokenGoogle = localStorage.getItem('googleToken')
  const {handleAuthWithGoogle} = useAuthWithGoogle()
  const {handleAuthWithApple} = UseAuthWithApple()
  const clientId = isPlatform('ios') ? 
  '771222230088-lke7bnckqef6rnaadn0auqsq2hdftnp6.apps.googleusercontent.com'
  :
  '771222230088-8aokcm4sc9lkm90e09j53o8vdbo3iu2t.apps.googleusercontent.com'
  
  const handleGoToLogin = ()=>{
    navigation.push('/login/signin', 'forward', "replace")
  }

  useIonViewWillEnter(() => {
    (async () => {
      setShowCreateAcc(false);
      setAvatarState({ id: null });
    })();
  });


  useEffect(()=>{
    GoogleAuth.initialize({ clientId: clientId
  })
},[])

const handleLoginGoogle = async()=>{
  
  const result = await GoogleAuth.signIn()
  if(result){
    const uuid = isPlatform('ios') ? `${result.email}-${Device.model}` : Device.uuid
    const body = {
      token: String(result?.authentication.idToken),
      client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
      deviceUUID: uuid
    }
    await handleAuthWithGoogle(body)
  }else{
    alert('Error')
  }

}

const handleLoginWithApple = async()=>{ 
  const result = await SignInWithApple.signin({
    requestedScopes: [
      ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
      ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
    ]
  })

  if(result){ 
    const decodedJwt:IAppleJWT = decode(result.identityToken)
    const uuid = isPlatform('ios') ? `${decodedJwt.email}-${Device.model}` : Device.uuid
    const body:IAuthWithApple = { 
      client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
      deviceUUID: uuid,
      email: result.email !== '' ? String(result.email) : String(decodedJwt.email),
      familyName: String(result.fullName?.familyName),
      givenName: String(result.fullName?.givenName),
      identityToken: String(result.identityToken),
      middleName: String(result.fullName?.middleName),
    }

    await handleAuthWithApple(body)
  }
}

  useIonViewDidEnter(async ()=>{ 
    if(tokenGoogle){
     await handleLoginGoogle()
    }
    
  })

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <article style={{ display:'flex', width: 300 }}>
              <IonButtons slot="start">
                <IonBackButton  className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/login/home' />
              </IonButtons>
              <p style={{ whiteSpace:'nowrap', fontSize: 22, marginLeft:10, fontWeight:700 }}>Creá tu cuenta</p>
            </article>
          </IonToolbar>
        </IonHeader>
        <div style={{ padding:20, width:'100%'}}>
          <div style={{ display:'flex', alignItems:'center', flexDirection:'column' }}>
            <GoogleButton handleLoginGoogle={handleLoginGoogle}  />
            {/* <FacebookButton /> */}
            { isPlatform('ios') && <AppleButton handleLoginApple={handleLoginWithApple} />}
          </div>
          <div style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'center' }}>
            <Divider width={'35%'} />
            <p style={{ width:'20%', fontSize:18, textAlign:'center' }}> o </p>
            <Divider width={'35%'} />
          </div>
          { !showCreateAcc && <div style={{ display:'flex', alignItems:'center', flexDirection:'column' }}>
            <IonButton onClick={()=> setShowCreateAcc(true)} color="orange" fill='solid' style={{ width:300, fontWeight:700 }}>
              <IonIcon slot="start" icon={mail}></IonIcon>
                Crear cuenta con Email
            </IonButton>
            <p onClick={handleGoToLogin} style={{ fontSize:14, fontWeight:400 }}> ¿Ya tienes cuenta?<span style={{marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}>Iniciar sesión</span> </p>
          </div>}
        </div>
        {
          showCreateAcc &&
          <SignUpForm />
        }
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          icon={toast.icon}
          cssClass={'css-toast'}
          buttons={toast.button}
          onDidDismiss={() => setToast({ isOpen:false })}
          duration={2000}
        ></IonToast>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <p style={{ textAlign:'center', fontSize:14, padding:'5px' }}> Al continuar, aceptas las 
            <span style={{marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}> Condiciones del servicio </span> y la 
            <span style={{marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}>Pólitica de privacidad</span> de Campus Huevo Sanchez. 
          </p>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};