import { IonText } from '@ionic/react';
import { Divider } from 'components/Divider';
import { useRecoilValue } from 'recoil';
import { trainingVideoModeAtom } from 'recoil/TrainingAtom';

interface IVideoTrainingBody {
  details: string;
}

export const VideoTrainingBody: React.FC<IVideoTrainingBody> = ({ details }) => {
  const videoTraningVal = useRecoilValue(trainingVideoModeAtom);
  return (
    <>

      <div style={{ display: videoTraningVal ? "none":'flex', width: videoTraningVal ? "20%":'100%', alignItems: 'center', flexDirection: 'column', position: videoTraningVal ? "absolute" : "relative", right: videoTraningVal ? "20px" : undefined, bottom: videoTraningVal ? "5px" : undefined }}>
        {!videoTraningVal && <Divider width="60%" marginTop="60px" />}
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '25px' }}>
          <IonText color={'dark'} style={{ fontSize: 24, fontWeight: 700 }}>
            {' '}
            Detalles claves{' '}
          </IonText>
        </div>
      </div>
      <div style={{ marginLeft: "32px", width: videoTraningVal ? "100%":"", position: videoTraningVal ? "absolute" : "relative", right: videoTraningVal ? "31px" : undefined, top: videoTraningVal ? "150px" : undefined }}>
      <div style={{ display: 'flex', width: videoTraningVal ? "16%":'100%', alignItems: 'center', flexDirection: 'column', position: videoTraningVal ? "absolute" : "relative", right: videoTraningVal ? "20px" : undefined, bottom: videoTraningVal ? "5px" : undefined }}>
       
          <div style={{ display: videoTraningVal ? "flex":'none', width: '100%', justifyContent: 'center', marginTop: '25px' }}>
            <IonText className={`${videoTraningVal ? "black-mode" : ""}`} color={'dark'} style={{ fontSize: videoTraningVal ? 20 : 24, fontWeight: 700 }}>
              {' '}
              Detalles claves{' '}
            </IonText>
          </div>
        </div>
        <div className={`dc-list-item ${videoTraningVal ? "black-mode" : ""}`} style={{position: videoTraningVal ? "absolute" : undefined, width:videoTraningVal ? "16%" : undefined, right:videoTraningVal ? "0":undefined , fontSize:videoTraningVal ? 14: 16 }} dangerouslySetInnerHTML={{ __html: String(details) }}></div>
      </div>
    </>
  );
};
